/*
Script que posa totes les imatges quadrades
 */

const d = document;
const w = window;
let selector = "[data-imgSquare]";

export default function imagesSquare() {
	resizeImages();
}

function resizeImages() {
	const items = d.querySelectorAll(selector);
	items.forEach((item) => {
		const cs = w.getComputedStyle(item);
		item.style.height = cs.width;
	});
}

w.addEventListener("resize", () => {
	resizeImages();
});

d.addEventListener("DOMContentLoaded", () => {
	resizeImages();
});
