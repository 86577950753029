const slide = document.getElementById("slide");
let myslider;

const playSlideOnVideoEnd = (video) => {
	video.currentTime = 0;
	video.play();

	video.addEventListener(
		"ended",
		(e) => {
			video.currentTime = 0;
			myslider.slideNext();
			myslider.autoplay.start();
		},
		false
	);
};

const reCreateHtmlCaption = (index) => {
	const caption = document.querySelector(`[data-caption-id="${index}"]`);
	if (caption) {
		const copy = caption.cloneNode(true);
		caption.insertAdjacentElement("beforebegin", copy);
		caption.remove();
	}
};

const activeLoop = true;

const showSlide = () => {
	if (!slide) return;

	const efecte = slide.dataset.efecte ?? "fade";

	let configInit = {
		autoHeight: slide.dataset.visualitzacio == "resize" ? true : false,
		initialSlide: 0,
		loop: activeLoop,
		pagination: {
			el: ".swiper-pagination",
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		autoplay: {
			delay: slide.dataset.pausetime ? slide.dataset.pausetime : 5000,
		},
		on: {
			transitionStart: function (e) {
				// Agafem l'index d'aquesta manera per evitar error en el loop
				//let index = e.activeIndex; // A vegades dona problemes
				let index = $(".swiper-slide-active").data("swiper-slide-index");

				const slideActive = document.querySelector(
					`.swiper-slide[data-id="${index}"]`
				);

				const video = slideActive.querySelector("video");
				if (video) {
					this.autoplay.stop();
					playSlideOnVideoEnd(video);
				}

				// Per activar animacions als captions
				if (efecte == "coverflow" || efecte == "cube") return;

				// Per fer efecte dels captions cada vegada
				reCreateHtmlCaption(index);

				// Hack perquè el "primer caption" funcioni quan es torna a començar el loop
				if (!index && activeLoop && efecte != "fade") {
					this.loopDestroy();
					this.loopCreate();
				}
			},
		},
	};

	// Afegim l'efecte que volem
	let customEffect = {};
	switch (efecte) {
		case "fade":
			customEffect = {
				effect: "fade",
				fadeEffect: { crossFade: true },
			};
			break;

		case "vertical":
			customEffect = {
				direction: "vertical",
			};
			break;

		case "cube":
			customEffect = {
				effect: "cube",
				grabCursor: true,
				cubeEffect: {
					shadow: true,
					slideShadows: true,
					shadowOffset: 20,
					shadowScale: 0.94,
				},
			};
			break;

		case "coverflow":
			customEffect = {
				effect: "coverflow",
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 3,
				coverflowEffect: {
					rotate: 50,
					stretch: 0,
					depth: 100,
					modifier: 1,
					slideShadows: true,
				},
			};
			break;
	}

	// Afegim totes les noves propietats
	let config = { ...configInit, ...customEffect };

	myslider = new Swiper(slide, config);
};

document.addEventListener("DOMContentLoaded", async (e) => {
	if (!slide) return;

	// Generem l'slide si tenim més d'un element
	const items = slide.querySelectorAll(".swiper-slide");
	if (items.length <= 1) return;

	// Generem l'slide
	showSlide();

	// Parem l'autoplay si el primer element de l'slide és un video
	const firstSlide = document.querySelector(`.swiper-slide[data-id="0"]`);
	if (firstSlide.querySelector("video")) {
		myslider.autoplay.stop();
	}
});
