/*
Script que talla totes les imatges a l'altura de la imatge més baixa
 */

const d = document;
const w = window;
const selector = "[data-imgCrop]";

const elements = d.querySelectorAll(selector);

const getMinHeight = () => {
	const elementsArray = Array.from(elements);

	const itemsHeight = elementsArray.map((item) => {
		return parseInt(w.getComputedStyle(item).height.replace("px", ""));
	});

	return Math.min.apply(null, itemsHeight.filter(Boolean));
};

const cropElements = () => {
	if (!elements) return false;

	const minHeight = getMinHeight();

	elements.forEach((element) => {
		element.style.height = minHeight + "px";
	});
};

w.addEventListener("resize", () => {
	cropElements();
});

d.addEventListener("DOMContentLoaded", () => {
	cropElements();
});
